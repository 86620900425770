import "styles/pages/page-blog.scss";

import React from "react";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import Breadcrumps from "components/Breadcrumps";

import { Content } from "page_components/quizy";

const Category = () => {
  const breadcrumps_data = [
    {
      name: "Darmowe Testy i Quizy",
    },
  ];

  return (
    <Layout>
      <Seo title="Darmowe Testy i Quizy" />
      <Breadcrumps data={breadcrumps_data} />
      <SubpageHeader title="Darmowe Testy i Quizy" />
      <Content />
    </Layout>
  );
};

export default Category;
